.ibb {
    color: var(--emeraude-850);
    background: var(--ecume-125);
    border-radius: 50%;
}

.ibg {
    color: var(--ecume-125);
    background: var(--emeraude-850);
    border-radius: 50%;
    box-shadow: inset 0 0 0 .1rem var(--ecume-125);

    &:hover, &:focus {
        background: var(--emeraude-900);
    }
}

.icg {
    color: var(--emeraude-850);
}

.lg {
    font-size: 4.8rem;
}

.btn {
    align-items: center;
    display: flex;
    gap: .8rem;
    line-height: normal;
}

.block-bg-dark {
    background: var(--ecume-125);

    * {
        color: var(--bs-white);
    }

    .card, .datepicker {
        * {
            color: var(--ecume-125);
        }
    }
}

.block-bg-light {
    background: var(--ecume-975);
}

a[target='_blank']:not(.btn) {
    .wysiwyg & {
        &::after {
            content: $incaicon-arrow_blank;
            font-family: $icomoon-font-family;
            padding-left: .5rem;
            font-size: 80%;
        }
    }
}

.block, %block {
    margin: 13.6rem 0;

    @include media-breakpoint-down(md) {
        margin: 6.4rem 0;
    }

    &[class*='bg-'] {
        margin: 0;
        padding: 13.6rem 0;

        @include media-breakpoint-down(md) {
            padding: 6.4rem 0;
        }
    }
}

.block-w-Title, %block-w-Title {
    margin-top: 4.8rem;

    @include media-breakpoint-down(md) {
        margin-top: 1.6rem;
    }

    &[class*='bg-'] {
        padding-top: 0;
    }
}

.image-line, %image-line {
    position: relative;

    &::after {
        background: var(--bs-white);
        bottom: 0;
        content: '';
        display: block;
        position: absolute;
        right: 2.4rem;
        top: 0;
        width: .2rem;
    }

    img {
        width: 100%;
        object-fit: cover;
    }
}

.custom-radius {
    @include media-breakpoint-up(lg) {
        border-radius: .4rem .4rem 2.4rem;
    }

    &-total {
        border-radius: .4rem .4rem 2.4rem;
    }
}

.mb-custom {
    margin-bottom: 13.6rem;

    @include media-breakpoint-down(md) {
        margin-bottom: 6.4rem;
    }
}

.mt-custom {
    margin-top: 13.6rem;

    @include media-breakpoint-down(md) {
        margin-top: 6.4rem;
    }
}

.popover {
    border: 0!important;


    &-arrow {
        --bs-popover-arrow-border: var(--ecume-360)!important;
    }

    &-body {
        padding: 0;
        border: 0;
    }
}

.image-100 {
    img {
        width: 100%;
        object-fit: cover;
    }
}

.material-symbols-outlined {
    &.filled {
        font-variation-settings: 'FILL' 1;
    }
}

.border-bottom-360 {
    border-bottom: .2rem solid var(--ecume-360);
}

.border-top-360 {
    border-top: .2rem solid var(--ecume-360);
}

.border-360 {
    border: .2rem solid var(--ecume-360);
}

.border-bottom-gray200 {
    border-bottom: .2rem solid $gray-200;
}

.border-top-gray400 {
    border-top: .2rem solid $gray-400;
}

.font-18 {
    font-size: 1.8rem;
}

.fw-bold {
    font-weight: 800!important;
}

.font-14 {
    font-size: 1.4rem;
}

.font-24-18 {
    font-size: 2.4rem;

    @include media-breakpoint-down(md) {
        font-size: 1.8rem;
    }
}

.text-capitalize-fl {
    &::first-letter {
        text-transform: uppercase;
    }
}

.external-link {
    &::after {
        content: $incaicon-arrow_blank;
        font-family: $icomoon-font-family;
        padding-left: .5rem;
        font-size: 80%;
    }
}

.w848 {
    max-width: 84.8rem;
    margin-left: auto;
    margin-right: auto;
}

.w63 {
    max-width: 63.1rem!important;
}

.mw-100 {
    max-width: 100%!important;
}

.align-items-initial {
    align-items: initial !important;
}

.lined-title {
    position: relative;
    line-height: 3rem;

    .text {
        position: relative;
        z-index: 1;
        background: var(--bs-white);
        padding-right: 1.6rem;
        display: inline-block;
    }

    &::after {
        height: .2rem;
        width: 100%;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        content: '';
        position: absolute;
        background: var(--ecume-125);
        border-radius: .2rem;
    }
}

.small-gap {
    margin-left: -.8rem;
    margin-right: -.8rem;

    > * {
        @include media-breakpoint-up(md) {
            padding-left: .8rem;
            padding-right: .8rem;

        }
    }
}

li {
    list-style-type: revert!important;
}

.block-bg-360 {
    background: var(--ecume-360);
}

.font-md-12 {
    @include media-breakpoint-down(md) {
        font-size: 1.2rem;
    }
}
