:root {
    --bs-body-font-size: 1.6rem;
    --background-primary: #17203b;
    --ecume-125: #17203b;
    --ecume-125-rgb: 23, 32, 59;
    --ecume-125--hover: #212d53;
    --ecume-200: #233164;
    --ecume-charte: #2f4077;
    --ecume-975: #eff2fd;
    --ecume-360: #3d539b;
    --emeraude-charte: #34cb6a;
    --emeraude-850: #69e2a1;
    --emeraude-850-darker: #279d59;
    --emeraude-900: #a6f2cf;
    --error: #9d2727;
    --success: #0e893e;
    --info: #526de4;

    --bs-link-color: #17203b;
    --bs-link-color-rgb: 23, 32, 59;
    --bs-link-hover-color: #121a2f;
    --bs-link-hover-color-rgb: 33, 45, 83;

    --bs-gray-850: #222940;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    font-size: 62.5%;
}

body {
    --bs-body-font-size: 1.6rem;
}

p, h1, h2, h3, h4, h5, h6, a, button, li, dt, dd, blockquote, cite, q, abbr, figcaption, label, input, select, textarea, caption {
    background-color: transparent; // for accessibility
}

picture {
    display: block;
}

button:focus:not(:focus-visible), .btn-close:focus, .btn:focus-visible {
    outline: revert;
}
