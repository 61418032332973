.font-primary {
    font-family: $font-primary;
}

.font-secondary {
    font-family: $font-secondary;
}

.title-md {
    font-weight: 800;
    font-size: 3.2rem;
    line-height: 1.2;
}
