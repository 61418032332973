.fr-mega-menu {
    position: fixed;
    width: 100vw;
    z-index: 999999;
    background: var(--bs-white);
    height: 100vh;
    top: 0;
    left: 0;
    padding-bottom: 9rem;

    @include media-breakpoint-down(lg) {
        padding-bottom: 0;
        overflow: auto;
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__collapse {
        position: absolute;
        width: 100%;
        height: 100% !important;
        padding: 2.4rem;
        left: 0;
        top: 0;
        background: var(--bs-white);
        z-index: 1;

        @include media-breakpoint-up(lg) {
            inset: 0 0 0 100% !important;
            padding: 2rem 1.6rem;
        }

        &::before {
            border-left: .2rem solid $gray-200;
            width: .2rem;
            left: 0;
            position: absolute;
            content: '';
            top: 4.8rem;
            bottom: 4.8rem;

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
    }

    &__leader {
        position: relative;
        padding-right: 2.4rem;

        @include media-breakpoint-down(lg) {
            padding-top: 2rem;
            padding-bottom: 2rem;
            margin-bottom: 1.6rem;
            border-bottom: .2rem solid $gray-200;
            margin-top: 4rem;
        }

        .ibg {
            position: absolute;
            right: 0;
            bottom: 0;

            @include media-breakpoint-down(lg) {
                bottom: initial;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    &__dialog {
        height: 100%;

        @include media-breakpoint-up(lg) {
            overflow: auto;
        }

        .fr-navigation {
            .fr-nav__list {
                .fr-nav__item {
                    &:last-child {
                        padding-bottom: 2rem;
                    }
                }
            }
        }
    }
}

.fr-link {
    &-title {
        font-size: 1.8rem;
        font-weight: 800;
        line-height: 1.44;
        margin-top: 3.2rem;
        margin-bottom: 2rem;

        @include media-breakpoint-down(lg) {
            margin-top: 2.4rem;
            margin-bottom: .8rem;

            .fr-mega-menu__leader & {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        .fr-link {
            display: block;
            text-decoration: none;
        }
    }

    &--back {
        background: transparent;
        border: none;
        padding: 0;
        display: flex;
        align-items: center;
        line-height: 4rem;
        font-weight: 800;
        color: var(--ecume-125);
        border-radius: 0;
    }
}

.fr-navigation {
    height: 100%;

    @include media-breakpoint-down(lg) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .menu-open & {
            display: block;
        }
    }
}

.fr-nav {
    &__close {
        display: flex;
        align-items: center;
        position: absolute;
        top: 1.4rem;
        right: 1.2rem;
        color: var(--ecume-125);
        background: transparent;
        border: none;
        line-height: 2.4rem;
        font-weight: 800;
        z-index: 2;
        padding: 0 1rem;
        border-radius: 0;

        @include media-breakpoint-down(lg) {
            top: 2.4rem;
            right: 2.4rem;
            line-height: 4rem;
        }

        .incaicon {
            font-size: 2.4rem;
            margin-left: .8rem;
            line-height: 1;
            font-weight: 400;
        }
    }

    &__title {
        font-size: 3.2rem;
        line-height: 1.4;
        font-weight: 800;

        @include media-breakpoint-down(lg) {
            @include visually-hidden;

            + .fr-link-title {
                margin-top: 6.4rem!important;
            }
        }

        + li {
            >.fr-link-title {
                @include media-breakpoint-down(lg) {
                    margin-top: 6rem;
                }
            }
        }
    }

    &__parent {
        width: 100%;
        position: relative;
        height: 100%;
        background: #fff;
        list-style: none;
        padding: 2.4rem;
        margin: 0;

        @include media-breakpoint-down(lg) {
            height: auto;

            .menu-open & {
                height: 100%;
            }
        }

        @include media-breakpoint-up(lg) {
            width: 25vw;
            padding: 3.1rem 2rem 0 4rem;
        }

        @include media-breakpoint-down(lg) {
            &::before {
                left: 2.4rem;
                right: 2.4rem;
                position: absolute;
                content: '';
                height: .2rem;
                border: none;
                border-bottom: 2px solid $gray-200;
                top: 6.4rem;
                z-index: 2;
            }
        }

        > li > .fr-mega-menu__collapse {
            &::before {
                top: 0;
                bottom: 0;
            }
        }

        > .fr-link-title {
            margin-bottom: .8rem;
        }

        > * {
            + .fr-link-title {
                margin-top: 2.4rem;
            }
        }
    }

    &__link {
        width: 100%;
        background: transparent;
        text-align: left;
        padding: .2rem 3.6rem .2rem .4rem;
        line-height: 1.625;
        border: none;
        position: relative;
        display: flex;
        align-items: center;
        text-decoration: none;

        .material-symbols-outlined {
            position: absolute;
            right: 0;
            font-size: 2.4rem;
        }

        &:hover {
            background: var(--ecume-975);
        }

        &:not(button) {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__bottom {
        padding: 2.4rem;

        @include media-breakpoint-up(lg) {
            position: absolute;
            left: 0;
            bottom: 0;
            height: 9rem;
            right: 0;
            padding-left: 5.6rem;
            display: flex;
            flex-direction: row;
            align-items: center;

            ul {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }

        .fr-link-title {
            margin: 0 4.8rem 0 0;
        }

        li {
            margin-right: 3.2rem;

            @include media-breakpoint-down(lg) {
                margin-right: 0;
                margin-top: .9rem;
            }

            .material-symbols-outlined {
                margin-left: .8rem;

                @include media-breakpoint-down(lg) {
                    &.ibg {
                        background: transparent;
                        border: none;
                        color: var(--emeraude-850);
                    }
                }

                &.ibg {
                    color: var(--ecume-125);
                }
            }

            a {
                display: flex;
                align-items: center;
                text-decoration: none;
                line-height: 1.8125;
            }
        }
    }

    &__item {
        margin-bottom: .1rem;

        &.last-column {
            .fr-mega-menu__collapse {
                padding: 1.6rem 1.6rem 1.25rem 4.8rem;
                position: relative;
                inset: 0!important;
                background: var(--ecume-975);

                @include media-breakpoint-down(lg) {
                    padding-left: 2.8rem;
                }

                &::before {
                    left: 3.2rem;
                    top: 1.6rem;
                    bottom: 1.6rem;
                    border-color: var(--ecume-360);
                    display: block!important;

                    @include media-breakpoint-down(lg) {
                        left: 1.6rem;
                    }
                }
            }

            button.fr-nav__link {
                background: var(--ecume-975);

                .material-symbols-outlined {
                    transition-duration: .3s;
                }

                &.collapsed {
                    background: transparent;

                    &:hover {
                        background: var(--ecume-975);
                    }

                    .material-symbols-outlined {
                        transform: rotate(180deg);
                    }
                }
            }

            a.fr-nav__link {
                line-height: 2.6rem;
                margin-top: 1.6rem;
                text-decoration: none;
                padding: 0;

                &:hover {
                    color: var(--ecume-125);
                    text-shadow: .75px 0 0 var(--ecume-125);
                }

                &:first-child {
                    margin-top: 0;
                }
            }

        }
    }
}

.container-btns {
    position: absolute;
    //z-index: 2;
    top: 2.4rem;

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

// sidemenu
.sidemenu {
    position: relative;
    background: var(--bs-white);
    border: .2rem solid var(--ecume-360);
    border-radius: .4rem .4rem 2.4rem;

    &-wrapper {
        margin-right: -1.6rem;
        margin-left: -1.6rem;

        @include media-breakpoint-down(lg) {
            position: fixed;
            top: 0;
            width: 100%;
            left: 0;
            margin-left: 0;
            margin-right: 0;
            overflow-y: scroll;
            overflow-x: hidden;
            max-height: 100vh;
            z-index: 12345;
        }

        .publications-catalig-acc & {
            @include media-breakpoint-up(lg) {
                max-width: 43.2rem;
                float: right;
                width: 100%;
            }
        }

        + .sidemenu-wrapper {
            margin-top: 1rem;
        }
    }

    &-close {
        position: absolute;
        top: 2.4rem;
        right: 2.4rem;
        display: flex;
        align-items: center;
        color: var(--ecume-125);
        background: transparent;
        border: none;
        font-weight: 800;
        padding: 0;
        z-index: 2;
        width: 2.4rem;
        height: 2.4rem;
        justify-content: center;

        &:hover, &:focus {
            outline: -webkit-focus-ring-color auto 1px!important;
        }
    }

    &-list {
        list-style: none;

        li {
            margin-bottom: .8rem;
        }
    }

    &-last {
        border-left: .2rem solid var(--ecume-125);

        li {
            margin-bottom: 1.6rem;

            &:last-child {
                margin-bottom: .8rem;
            }
        }

        &.sidemenu-list-parent {
            border-left: 0;
        }
    }

    &-btn {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        background-color: transparent;
        border: none;
        padding: 0;
        display: flex;
        margin-bottom: .8rem;

        .material-symbols-outlined {
            flex-shrink: 0;
            transition-duration: .3s;
        }

        .text {
            text-align: left;
        }

        &[aria-expanded='true'] {
            .material-symbols-outlined {
                transform: rotate(90deg);
            }
        }
    }

    &-collapse {
        &-parent {
            ul {
                padding-left: 1.6rem;
            }

            &::before {
                top: 0;
                bottom: -.8rem;
                content: '';
                display: none;
                background-color: var(--ecume-975);
                position: absolute;
                left: -2.4rem;
                right: -2.4rem;
            }

            &.show {
                position: relative;

                &::before {
                    display: block;
                }

                > * {
                    position: relative;
                }
            }
        }
    }

    &-link {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        &.active {
            font-weight: 800;
        }
    }

    &-title {
        font-size: 2.4rem;
        font-weight: 800;
        padding-right: 3rem;
    }

    &-subtitle {
        font-size: 1.8rem;
        font-weight: 800;
        padding-right: 3rem;
        line-height: 2.4rem;
    }

    &-control {
        background: transparent;
        border: .2rem solid var(--ecume-125);
        border-radius: .4rem 0 0 .4rem;
        width: 4rem;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.1rem;
        position: absolute;
        right: -1.6rem;
        top: 0;

        &.position-relative {
            position: relative;
            border-color: var(--bs-white);
            color: var(--bs-white);
            top: initial;
            right: initial;
        }

        &-wrapper {
            position: relative;

            @include media-breakpoint-down(lg) {
                padding-right: 5rem;
            }
        }
    }

    &-item {
        .relative {
            position: relative;
            padding-right: 3.2rem;

            button {
                position: absolute;
                right: 0;
                top: .4rem;
                display: block;
                width: 2.4rem;
                height: 2.4rem;
                margin: 0;
            }
        }
    }
}
