//INCA icons font
@import '../../fonts/inca/incaicon/style';

/* Marianne  */

@font-face {
    font-family: Marianne;
    src: url('../../fonts/marianne/Marianne-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: Marianne;
    src: url('../../fonts/marianne/Marianne-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Marianne;
    src: url('../../fonts/marianne/Marianne-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Marianne;
    src: url('../../fonts/marianne/Marianne-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Marianne';
    src: url('../../fonts/marianne/Marianne-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
}

