.search-modal {
    background-color: rgb($ecume-125, .8);

    .btn-close {
        --bs-btn-close-color: #{$white};
        --bs-btn-close-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e');
        display: flex;
        align-items: center;
        position: absolute;
        top: 6rem;
        right: 6rem;
        color: $white;
        width: auto;
        opacity: 1;
        background-position: right .5rem center;
        padding: 1rem 3.5rem 1rem 1rem;

        @include media-breakpoint-down(md) {
            top: 1rem;
            right: 1rem;
        }
    }

    .modal-header {
        padding: 18rem 0 0;
        border: 0;

        @include media-breakpoint-down(md) {
            padding: 8rem 0 0;
        }
    }

    .modal-content {
        border: 0;
        background-color: transparent;
    }
}

.aa-Form {
    display: flex;
    padding: 0;
    height: 6.9rem;
    border: .2rem solid $gray-400;
    border-radius: .4rem;
    background-color: $white;
    margin-bottom: 1rem;

    @include media-breakpoint-down(md) {
        height: 4.8rem;
    }
}

.aa-InputWrapper {
    width: 100%;
}

.aa-InputWrapperPrefix {
    order: 1;
    height: 100%;

    .aa-Label {
        height: 100%;
    }
}

.aa-InputWrapperSuffix {
    .aa-ClearButton {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .8rem;
        border: 0;
        box-shadow: none;

        &::before {
            content: attr(title);
            font-size: 1.6rem;
            font-weight: 400;
            color: $ecume-125;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }

    .aa-ClearIcon {
        height: 2.4rem;
        width: 2.4rem;
    }
}

.aa-Input {
    font-size: 1.8rem;
    font-weight: 800;
    border: 0;
    color: $gray-850;
    height: 100%;
    padding: 1.6rem;
    width: 100%;
    outline: 0;

    &::placeholder {
        font-weight: 400;
    }

    &::-webkit-search-cancel-button {
        display: none;
    }
}

.aa-SubmitButton {
    height: 6.4rem;
    width: 6.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    border-left: .1rem solid $gray-400;
    padding: 0;

    @include media-breakpoint-down(md) {
        height: 4.48rem;
        width: 4.8rem;
    }

    .aa-SubmitIcon {
        height: 3.7rem;
        width: 3.7rem;

        @include media-breakpoint-down(md) {
            height: 3rem;
            width: 3rem;
        }
    }
}

.aa-Panel {
    background-color: $white;
    border: .2rem solid $ecume-360;
    border-radius: .4rem .4rem 2.4rem;
    padding: .8rem 0;
}

.aa-ItemContent {
    .aa-ItemIcon {
        height: 2.4rem;
        width: 2.4rem;
    }
}

.aa-List {
    margin: 0;
    padding: 0;
    list-style-type: none;

    > li {
        &.aa-Item {
            cursor: pointer;
        }

        .aa-ItemWrapper {
            padding: .4rem 1.6rem;
            font-size: 1.6rem;
            font-weight: 400;
            display: flex;
            gap: .8rem;

            &:hover, &:focus {
                background-color: $ecume-975;
            }

            .aa-ItemContent {
                display: flex;

                .aa-ItemIcon {
                    display: none;
                }

                .aa-ItemContentBody:has(mark) {
                    font-weight: 800;

                    mark, .mark {
                        padding: 0;
                        font-weight: 400;
                        background-color: transparent;
                    }
                }
            }

            .aa-ItemActions {
                .aa-ItemActionButton {
                    visibility: hidden;
                    width: 2.4rem;
                    height: 2.6rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:focus, &:focus-visible {
                        visibility: visible;
                    }
                }
            }
        }
    }
}

.ais-Snippet-wrapper, .ais-Highlight {
    mark, .mark {
        color: $ecume-360;
        padding: 0;
        font-weight: 800;
        background-color: transparent;
    }
}

.fake-search {
    .ais-SearchBox-label {
        display: block;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.625;
        margin-bottom: .3rem;
    }
}

.ais-SearchBox-form {
    display: flex;
    justify-content: space-between;
    background-color: $white;
    border-radius: .5rem;
    height: 4.2rem;

    .ais-SearchBox-input {
        color: $gray-850;
        background-color: $white;
        border: 0;
        border-radius: 0;
        padding: .8rem;
        width: 100%;

        &::-webkit-search-cancel-button {
            display: none;
        }
    }

    .ais-SearchBox-resetIcon {
        border: 0;
    }
}

.ais-SearchBox {
    .ais-SearchBox-submit {
        border-radius: .2rem;
        border: 0;
        width: 4rem;
        order: 3;
        display: flex;
        justify-content: center;
        align-items: center;

        .ais-SearchBox-submitIcon {
            height: 1.6rem;
            width: 1.6rem;
        }
    }

    .ais-SearchBox-reset {
        border: 0;
        width: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .ais-SearchBox-resetIcon {
            height: 1.4rem;
            width: 1.4rem;
        }
    }
}

.results-count-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
    font-size: 1.6rem;

    @include media-breakpoint-down(md) {
        flex-direction: column;
        padding: 1.6rem 0;
    }

    .ais-Stats {
        font-size: 1.8rem;
        font-weight: 800;

        @include media-breakpoint-down(md) {
            margin-bottom: 1.6rem;
        }
    }

    .ais-Stats-label {
        display: block;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.625;
        margin-bottom: 0;
        flex: 1 0 auto;
    }

    .ais-Stats-wrapper {
        display: flex;
        gap: 2rem;
        align-items: center;

        @include media-breakpoint-down(md) {
            gap: 1rem;
        }
    }
}

.clinical-trial-collapse select, .ais-SortBy-select {
    --bs-form-select-bg-img: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e');
    --bs-form-select-bg-icon: null;
    height: 4.2rem;
    display: block;
    width: 100%;
    padding: .375rem 3rem .375rem .75rem;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.625;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-image: var(--bs-form-select-bg-img);
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 1.6rem 1.2rem;
    border: .1rem solid var(--bs-border-color);
    border-radius: .375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.ais-CurrentRefinements-list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
}

.ais-CurrentRefinements-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: .8rem;
    margin-top: 1.6rem;
    margin-right: .8rem;

    .ais-CurrentRefinements-label {
        display: none;
    }
}

.ais-CurrentRefinements-category {
    display: flex;
    justify-content: space-between;
    border: .2rem solid $white;
    border-radius: .4rem .4rem .8rem 0;
    padding: .8rem;
    gap: .8rem;
    position: relative;

    .ais-CurrentRefinements-delete {
        border: 0;
        background-color: transparent;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.clinical-trial-collapse {
    ul {
        list-style-type: none;
        margin: 0 0 2.5rem;
        padding: 0;

        > li {
            margin-bottom: .5rem;

            > label {
                display: flex;
                align-items: flex-start;

                input[type=checkbox] {
                    margin-top: .3rem;
                }
            }
        }
    }

    .collapsing, .collapse {
        margin-top: 2.5rem;
    }

    .form-check-inline {
        margin-left: 1rem;
    }

    input[type='checkbox'] {
        appearance: none;
        -webkit-appearance: none;
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: 2rem;
        padding: 0;
        border: .25rem solid $ecume-125;
        border-radius: .5rem;
        background-color: inherit;

        &::before {
            content: '';
            width: 1.8rem;
            height: 1.8rem;
            clip-path: path('M16 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.89 18 2 18H16C17.11 18 18 17.1 18 16V2C18 0.9 17.11 0 16 0ZM7 14L2 9L3.41 7.59L7 11.17L14.59 3.58L16 5L7 14Z');
            transform: scale(0);
            background-color: $ecume-125;
            margin: -.1rem;
        }

        &:checked::before {
            transform: scale(1);
        }
    }
}

.ais-ClearRefinements {
    display: flex;
    justify-content: center;

    &-button {
        color: $gray-850;
        cursor: pointer;
        font-size: 1.6rem;
        font-weight: 800;
        line-height: 1.2;
        padding: 1.6rem;
        align-items: center;
        display: flex;
        gap: .8rem;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        user-select: none;
        border: .2rem solid $gray-850;
        border-radius: 3.2rem;
        background-color: $white;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

        @include media-breakpoint-down(md) {
            font-size: 1.4rem;
        }

        &:hover, &:focus {
            color: $white;
            background-color: $gray-850;
            border-color: $gray-850;
        }

        &--disabled {
            border-color: $gray-700;
            color: $gray-700;
            cursor: auto;

            &:hover, &:focus {
                color: $gray-700;
                background-color: $white;
                border-color: $gray-700;
            }
        }
    }
}

.show-more {
    border: 0;
    padding: .2rem;
    font-size: 1.6rem;
    border-bottom: .1rem solid $ecume-125;
}

.list-facets {
    &--help {
        font-size: 1.4rem;
        max-width: 25.7rem;
        margin: 0 auto;
    }

    &--title {
        font-size: 1.4rem;
        font-weight: 800;
        line-height: 1.5714;
    }
}



