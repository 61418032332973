.header-inca {
    background-color: $white;
    position: sticky;
    transition: all .4s ease !important;
    width: 100%;
    z-index: 9999;

    &.header-sticky {
        .header-inca__inner {
            border-bottom: .1rem solid $gray-500;
        }
    }

    &__inner {
        padding: 3rem 0;
        border-bottom: .1rem solid $gray-500;

        @include media-breakpoint-down(md) {
            padding: 1.6rem 0;
        }

        .hp-borderless & {
            border-bottom: 0;
            margin-bottom: 0;
        }
    }

    &__top {
        display: flex;
        flex-direction: row;

        @include media-breakpoint-down(md) {
            align-items: center;
        }
    }

    &__bottom {
        p {
            margin-bottom: 0;

            @include media-breakpoint-down(md) {
                font-size: 1.2rem;
            }
        }
    }

    .nav-infos {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        text-align: right;

        p {
            margin-bottom: 1.6rem;

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }

        strong {
            font-size: 1.4rem;
            font-weight: 700;
        }

        ul {
            gap: 1.6rem;
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: flex-end;

            li {
                padding: 0;
                margin: 0;
            }

            .btn {
                border-radius: 0;
                color: $ecume-125;
                font-size: 4rem;
                padding: 1.15rem;

                @include media-breakpoint-down(md) {
                    font-size: 2.6rem;
                    padding: .65rem;
                }
            }
        }
    }
}

.baseline-inca {
    padding: 2rem 0;
    background-color: #16203b;
    margin-bottom: 1.6rem;

    @include media-breakpoint-down(md) {
        padding: .5rem 0;
        flex-direction: column;
        gap: 1rem;
    }

    .hp-borderless + & {
        margin-bottom: 0;
    }

    .container {
        height: 100%;
    }

    &__inner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: $white;
        gap: 3rem;

        @include media-breakpoint-down(lg) {
            gap: 2rem;
        }

        @include media-breakpoint-down(md) {
            flex-direction: column;
            gap: 1rem;
        }

        figure {
            margin-bottom: 0;
        }
    }

    &__text {
        margin-right: 2rem;
        margin-bottom: 0;
        margin-top: .5rem;
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: -.019rem;

        @include media-breakpoint-down(lg) {
            font-size: 1.6rem;
            margin-top: 0;
            margin-right: 0;
        }

        @include media-breakpoint-down(md) {
            font-size: 1.4rem;
        }

        p {
            margin-bottom: 0;
        }
    }
}
