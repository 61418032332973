@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?4j6il9') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?4j6il9') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?4j6il9') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?4j6il9') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?4j6il9##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.incaicon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.incaicon-edit {
  &:before {
    content: $incaicon-edit;
  }
}
.incaicon-basket {
  &:before {
    content: $incaicon-basket;
  }
}
.incaicon-user {
  &:before {
    content: $incaicon-user;
  }
}
.incaicon-fight {
  &:before {
    content: $incaicon-fight;
  }
}
.incaicon-etiology {
  &:before {
    content: $incaicon-etiology;
  }
}
.incaicon-treatment {
  &:before {
    content: $incaicon-treatment;
  }
}
.incaicon-diagnostic {
  &:before {
    content: $incaicon-diagnostic;
  }
}
.incaicon-menu-2 {
  &:before {
    content: $incaicon-menu-2;
  }
}
.incaicon-close {
  &:before {
    content: $incaicon-close;
  }
}
.incaicon-angle-right {
  &:before {
    content: $incaicon-angle-right;
  }
}
.incaicon-date_range {
  &:before {
    content: $incaicon-date_range;
  }
}
.incaicon-location_pin {
  &:before {
    content: $incaicon-location_pin;
  }
}
.incaicon-arrow_blank {
  &:before {
    content: $incaicon-arrow_blank;
  }
}
.incaicon-instagram {
  &:before {
    content: $incaicon-instagram;
  }
}
.incaicon-linkedin {
  &:before {
    content: $incaicon-linkedin;
  }
}
.incaicon-youtube {
  &:before {
    content: $incaicon-youtube;
  }
}
.incaicon-x {
  &:before {
    content: $incaicon-x;
  }
}
.incaicon-facebook {
  &:before {
    content: $incaicon-facebook;
  }
}
.incaicon-search {
  &:before {
    content: $incaicon-search;
  }
}
.incaicon-menu {
  &:before {
    content: $incaicon-menu;
  }
}
.incaicon-file_download {
  &:before {
    content: $incaicon-file_download;
  }
}
.incaicon-share {
  &:before {
    content: $incaicon-share;
  }
}
.incaicon-mail_outline {
  &:before {
    content: $incaicon-mail_outline;
  }
}
.incaicon-stay_current_portrait {
  &:before {
    content: $incaicon-stay_current_portrait;
  }
}
.incaicon-phone {
  &:before {
    content: $incaicon-phone;
  }
}
.incaicon-prevention {
  &:before {
    content: $incaicon-prevention;
  }
}
.incaicon-biology {
  &:before {
    content: $incaicon-biology;
  }
}

