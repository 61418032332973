h1, .like-h1 {
    font-size: $h1-font-size;
    font-weight: 800;

    @include media-breakpoint-down(md) {
        font-size: 2.8rem;
    }
}

h2, .like-h2 {
    font-size: $h2-font-size;
    font-weight: 800;

    @include media-breakpoint-down(md) {
        font-size: 2.4rem;
    }
}

h3, .like-h3 {
    font-size: $h3-font-size;
    font-weight: 800;

    @include media-breakpoint-down(md) {
        font-size: 2rem;
    }
}

.like-nb-results {
    font-size: $h4-font-size;
    font-weight: 800;
}
