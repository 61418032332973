.skiplinks {
    background-color: var(--ecume-125--hover);
    padding: 1.6rem 0;
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    width: 100%;

    &:focus-within {
        position: relative;
        transform: translateY(0);
    }

    &__list {
        list-style: none;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0 -1.6rem -1.5rem;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0 -1.6rem;
        }

        > li {
            margin: 0 1rem 1rem;

            @include media-breakpoint-up(lg) {
                margin: 0 1rem;
            }

            .a-link {
                --bs-btn-active-color: var(--bs-white);
                color: var(--bs-white);
                display: inline-block;
                font-size: 1.6rem;
                font-weight: 400;
                padding: .4rem 1rem;
                line-height: 1.525;
                outline: 0;
                text-decoration: underline;

                &:hover, &:focus {
                    text-shadow: .05rem 0 0 var(--bs-white);
                    border-color: var(--bs-white);
                }
            }
        }
    }
}
