.social-networks {
    @include media-breakpoint-down(md) {
        border-bottom: .1rem solid $gray-200;
        border-top: .1rem solid $gray-200;
    }

    &-text {
        font-size: 1.8rem;
        font-weight: 800;
    }

    &-list {
        a {
            display: block;
            text-decoration: none ;
        }

        .incaicon {
            font-size: 2.4rem;

            .article-section & {
                color: rgba(var(--ecume-125-rgb), .6);
            }

            .block-bg-light & {
                color: var(--ecume-125);
            }
        }

        .material-symbols-outlined {
            align-items: center;
            background: var(--ecume-125);
            border-radius: 50%;
            color: var(--bs-white);
            display: flex;
            font-size: 1.8rem;
            justify-content: center;
            height: 2.4rem;
            width: 2.4rem;

            .article-section & {
                background: rgba(var(--ecume-125-rgb), .6);
            }

            .block-bg-light & {
                background: var(--ecume-125);
            }
        }
    }

    &-dropdown {
        .dropdown-menu {
            --bs-dropdown-min-width: auto;
            --bs-dropdown-padding-y: 0;
            border: 0;

            @include media-breakpoint-up(md) {
                position: relative;
            }

            &.show {
                display: flex;
                flex-direction: column;
            }
        }

        .dropdown-toggle {
            border: 0;
            color: var(--ecume-125);
            font-size: 1.8rem;
            padding: .3rem;

            &::after {
                display: none;
            }
        }
    }
}
