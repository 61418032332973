.pagination {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.page {
    &-item {
        margin-left: .8rem;
        margin-bottom: .4rem;

        @include media-breakpoint-down(md) {
            margin-left: .4rem;
        }

        &:first-child {
            margin-left: 0;
            margin-right: 2.4rem;

            @include media-breakpoint-down(md) {
                margin-right: 1.2rem;
            }

            .page-link {
                border: none!important;
                border-radius: .2rem;
            }
        }

        &:last-child {
            margin-left: 3.2rem;
            margin-right: 0;

            @include media-breakpoint-down(md) {
                margin-left: 1.6rem;
            }

            .page-link {
                border: none!important;
                border-radius: .2rem;
            }
        }
    }

    &-link {
        min-width: 3.2rem;
        height: 3.2rem;
        padding: 0;
        line-height: 3.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: .2rem solid transparent;
        border-radius: .2rem .2rem 1.6rem;
        cursor: pointer;

        .active & {
            background: transparent;
            color: var(--ecume-125);
            border-color: var(--ecume-125);
            font-weight: 800;
            cursor: default;
        }

        .disabled & {
            background: transparent;
            pointer-events: none;
        }
    }
}
