$values: 4, 5, 6;

@media print {
    //basic
    *:not(.plyr__control):not(.plyr__poster), *::before, *::after, *::first-letter, p::first-line, div::first-line, blockquote::first-line, li::first-line {
        background: transparent !important;
        color: var(--ecume-125)!important;
        box-shadow: none !important;
        text-shadow: none !important;
    }

    h1, h2, h3, h4, h5, h6, caption, .title-md, .sitemap-title {
        page-break-after: avoid;
        break-after: avoid;
    }

    @page {
        size: 1320px;
        margin: 2rem 0;
    }

    //grid and sizes
    .container {
        padding-left: 0!important;
        padding-right: 0!important;
    }

    .row {
        margin-left: 0!important;
        margin-right: 0!important;

        > * {
            width: 100% !important;
            margin-left: 0!important;
            padding-left: 0!important;
            padding-right: 0!important;
        }
    }

    .title-block-inner .title-block-item, .video-block, .article-section, .card-inca-contact {
        max-width: 80% !important;
    }

    .article-section {
        margin-bottom: 2.4rem!important;
    }

    .container {
        max-width: 80% !important;
    }

    .title-block, .article-section-chapo, .article-section-date, .text-image, .half-overlapped-wrapper, .references-block, .block {
        padding: 0!important;
        margin: 2.4rem 0!important;
    }

    .justify-content-center.justify-content-md-start {
        justify-content: start!important;
    }

    .table, .media-full-block-info {
        margin: 2.4rem 0!important;
    }

    .accordion-faq .accordion-body {
        padding-bottom: 2.4rem!important;
    }

    blockquote {
        margin-bottom: 0!important;
        padding-top: 2.4rem!important;
        padding-bottom: 2.4rem!important;
    }

    .accordion-faq .accordion-button .material-symbols-outlined {
        height: 3rem!important;
        flex: 0 0 3rem!important;
    }

    .accordion-collapse {
        display: block!important;
    }

    .latest-articles, .card-inca-key-figure-wrapper, {
        padding: 0!important;
    }

    .half-overlapped .card-inca {
        margin: 0!important;
    }

    .card-inca {
        border-top-width: .2rem!important;
        border-bottom-width: .2rem!important;
        border-left-width: .2rem!important;
        border-right-width: .2rem!important;
        margin: 0!important;
        border-radius: .4rem .4rem 2.4rem!important;
    }

    .text-image.text-image-w-podcast .card-inca .card-body {
        padding: 2.2rem 2.4rem 1.6rem!important;
    }

    .card-inca-horizontal .card-body .card-end {
        max-width: 100% !important;
    }

    .half-overlapped .card-inca .card-body .material-symbols-outlined {
        position: relative!important;
        right: 0 !important;
        bottom: 0 !important;
    }

    //header
    .header-inca {
        position: relative!important;
        .nav-infos {
            nav {
                display: none;
            }
        }
    }

    .header-sticky {
        position: relative!important;
    }

    //bg and colors
    .text-bg-dark, .block-bg-dark {
        background: transparent!important;

        * {
            color: var(--ecume-125)!important;
        }
    }

    .wysiwyg blockquote, .quote-block-text__decor {
        &::before {
            opacity: .1!important;
        }
    }

    //forms and blocks
    .sidemenu-wrapper, .sidemenu-control, .social-networks-dropdown, .cancer-form-wrapper, .swiper, .form-moteur, .scrollspy, .podcast-wrapper, .video-block-wrapper, .sticky-menu, .nav-tabs, .pagination, .breadcrumb {
        display: none!important;
    }

    .card-header.d-none {
        display: flex !important;
    }

    .half-overlapped .card-inca {
        height: auto!important;
    }

    .visual-insert-wrapper .container > .row [class$='-6'] .card-inca, .visual-insert-wrapper .container > .row [class$='-4'] .card-inca {
        height: 15rem!important;
    }

    .half-overlapped-wrapper .container > .row, .visual-insert-wrapper .container > .row {
        gap: 2.4rem!important;
    }

    .quote-block-info {
        width: 100% !important;
        max-width: 100% !important;
    }

    .card-inca-horizontal .card-body {
        padding-bottom: 2.4rem!important;
    }

    .container {
        .container {
            padding-left: 0!important;
            padding-right: 0!important;
        }
    }

    .half-overlapped [class*=col-].order-1 {
        margin-bottom: 1rem !important;
    }

    .overlapping-text [class*='col-'].order-1 {
        margin-bottom: 1rem !important;
        margin-right: 0 !important;
    }

    .media-full-block-info {
        color: var(--ecume-125)!important;
        position: relative !important;
    }

    .text-image-img {
        margin-bottom: 1rem !important;
    }

    //lists
    .list-articles-results {
        display: none!important;
    }

    .latest-articles-content {
        > * {
            margin-bottom: 20px!important;
        }
    }

    //cards
    .card, .push, ul {
        page-break-inside: avoid;
    }

    .card-inca-no-border {
        border: .2rem solid var(--ecume-360)!important;
    }

    .card {
        --bs-card-border-color: var(--ecume-360)!important;
    }


    //footer etc
    .footer, .btn-to-top {
        display: none!important;
    }

    // margins and padings
    @each $value in $values {
        .mb-#{$value} {
            margin-bottom: 2.4rem!important;
        }

        .mt-#{$value} {
            margin-top: 2.4rem!important;
        }

        .mb-lg-#{$value} {
            margin-bottom: 2.4rem!important;
        }

        .mt-lg-#{$value} {
            margin-top: 2.4rem!important;
        }

        .mb-md-#{$value} {
            margin-bottom: 2.4rem!important;
        }

        .mt-md-#{$value} {
            margin-top: 2.4rem!important;
        }
    }

    .multiple-articles .row > [class*=col-]:first-child .card {
        height: auto!important;
        margin-bottom: 2.4rem!important;
    }

    .card-img-overlay {
        position: relative!important;
    }

    .col-md-7.mx-auto {
        width: 100% !important;
    }

    picture {
        text-align: center!important;
    }
}
