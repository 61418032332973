.wysiwyg {
    font-size: 1.6rem;
    word-wrap: break-word;

    @include media-breakpoint-down(md) {
        font-size: 1.4rem;
    }

    h3 {
        font-size: 2.4rem;
        margin: 6.4rem 0 .8rem;
    }

    p {
        margin-bottom: 1.6rem;
    }

    strong {
        font-size: 1.8rem;
        font-weight: 800;

        &.font-16 {
            font-size: 1.6rem;
        }
    }

    > .ibexa-embed-type-image {
        @include media-breakpoint-down(md) {
            margin-left: -1.6rem;
            margin-right: -1.6rem;
        }

        &[class*=align-] {
            margin-bottom: 1.6rem;

            @include media-breakpoint-down(md) {
                text-align: center;
            }
        }

        &.align-center {
            text-align: center;
        }

        &.align-left {
            @include media-breakpoint-up(md) {
                float: left;
                margin-right: 1.6rem;
            }
        }

        &.align-right {
            @include media-breakpoint-up(md) {
                float: right;
                margin-left: 1.6rem;
            }
        }

        .fr-content-media {
            margin: 0;
        }

        img {
            border-radius: .4rem .4rem 2.4rem;

            @include media-breakpoint-down(md) {
                border-radius: 2.4rem .4rem;
            }
        }
    }

    ul {
        padding-left: 0;
        list-style: none;

        li {
            position: relative;
            padding-left: 2.4rem;

            &::before {
                width: .4rem;
                height: .4rem;
                background: var(--bs-body-color);
                left: 1rem;
                position: absolute;
                content: '';
                display: block;
                border-radius: 50%;
                top: 1.2rem;

                @include media-breakpoint-down(md) {
                    top: 1rem;
                }
            }
        }
    }

    .list-result {
        padding-left: 0;
        list-style: none;
        li {
            padding-left: 0;
            &::before {
                content: none;
            }
        }

        .list-result-item {
            border-bottom: .2rem solid var(--ecume-360);
            padding-bottom: 2.4rem;

            ul {
                list-style-type: disc;
                border-bottom: none;
                padding-left: 3rem;
            }
        }
    }

    .accessible-embed-list {
        list-style: none;
        padding-left: 0;

        li {
            margin-bottom: 0;
        }
    }

    iframe {
        max-width: 100%;
    }

    blockquote {
        padding: 3.2rem 2.4rem 2.4rem 4.8rem;
        position: relative;

        @include media-breakpoint-down(lg) {
            padding: 2.4rem 0 2.4rem 1.6rem;
        }

        p {
            font-size: 3.2rem;
            line-height: 1.2;
            font-weight: 800;
            position: relative;

            @include media-breakpoint-down(sm) {
                font-size: 2.4rem;
            }
        }

        &::before {
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTAiIGhlaWdodD0iNzQiIHZpZXdCb3g9IjAgMCA5MCA3NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQ4Ljc4ODUgNzMuODI4NkwyNS41MDMxIDM3LjQ2OEw0OC43ODg1IDBIMjMuNjU1TDAgMzcuNjUyNkwyMy42NTUgNzMuODI4Nkg0OC43ODg1Wk05MCA3My44Mjg2TDY2LjcxNDYgMzcuNDY4TDkwIDBINjQuODY2NUw0MS4yMTE1IDM3LjY1MjZMNjQuODY2NSA3My44Mjg2SDkwWiIgZmlsbD0iIzY5RTJBMSIvPgo8L3N2Zz4K');
            content: '';
            width: 9rem;
            height: 7.4rem;
            position: absolute;
            top: 3.2rem;
            left: -1.7rem;

            @include media-breakpoint-down(sm) {
                top: 1.6rem;
                left: 0;
            }

            @include media-breakpoint-down(lg) {
                top: 2.4rem;
            }
        }

        a {
            text-decoration: none;
            font-weight: 800;
            .ibg {
                vertical-align: middle;
                margin-left: .8rem;
                font-weight: 400;
            }
        }
    }

    &.block--richtext {
        margin-top: 6.4rem;
        margin-bottom: 6.4rem;

        .container {
            --bs-gutter-y: 0;
        }

        .col-md-7 {
            .no-side-menu & {
                margin-right: auto !important;
                margin-left: auto !important;
                width: 100% !important;
            }

            .has-side-menu & {
                @include media-breakpoint-down(lg) {
                    width: 100% !important;
                }
            }
        }

        &.block-bg-dark {
            padding: 2.6rem;

            @include media-breakpoint-down(lg) {
                padding: 3.2rem 0;
            }

            .no-side-menu & {
                padding: 2.6rem;
            }
        }
    }
}
