.sticky-menu {
    background: var(--bs-white);
    overflow: hidden;
    padding: .8rem 0;
    position: fixed;
    right: 0;
    top: 23.6rem;
    transition: all .5s;
    width: 7.2rem;
    z-index: 5;

    @include media-breakpoint-up(md) {
        border-radius: .8rem 0 0 .8rem;
        border-top: .1rem solid var(--ecume-360);
        border-bottom: .1rem solid var(--ecume-360);
        border-left: .1rem solid var(--ecume-360);
    }

    @include media-breakpoint-down(md) {
        border-top: .2rem solid var(--ecume-360);
        bottom: 0;
        left: 0;
        padding: .8rem 1.6rem;
        right: 0;
        top: auto;
        width: auto;
    }

    &.has-focus, &.has-hover {
        overflow: hidden;
        width: 28.7rem;

        @include media-breakpoint-down(md) {
            overflow: visible;
            width: auto;
        }
    }

    &-list {
        display: flex;
        flex-direction: column;
        margin: 0;
        width: 28.7rem;

        @include media-breakpoint-down(md) {
            flex-direction: row;
            justify-content: space-between;
            margin: 0 auto;
            max-width: 32.8rem;
            width: auto;
        }

        li {
            a {
                align-items: center;
                display: flex;
                font-size: 1.4rem;
                gap: 0 1.6rem;
                padding: .8rem 1.6rem;
                text-decoration: none;

                @include media-breakpoint-down(md) {
                    padding: .8rem;
                }

                &:hover, &:focus {
                    background: var(--ecume-975);
                }
            }
        }

        img {
            font-size: 4rem;
        }

        .btn-go-to-top {
            border: 0;
            border-radius: 0;
            color: var(--ecume-125);
            width: 4.8rem;
            padding: 1.2rem .8rem;

            &:hover, &:focus {
                background: var(--ecume-975);
            }

            .material-symbols-outlined {
                font-size: 3.2rem;
            }
        }
    }
}
