.btn {
    &[class*='btn-outline-'] {
        --bs-border-width: .2rem;
        --bs-btn-font-weight: 800;
    }

    &-link {
        --bs-btn-font-weight: 800;
        border: 0;
        text-decoration: none;
    }

    &-link-icon {
        display: inline-block !important;
        padding: .5rem 0;
        text-align: left;

        .text {
            font-weight: 800;
        }

        .material-symbols-outlined {
            font-size: 2rem;
            line-height: 1;
            position: relative;
            top: .5rem;
        }

        &:hover {
            .text {
                text-decoration: underline;
            }
        }
    }
}

.btn-outline-primary {
    --bs-btn-hover-bg: var(--bs-white);
    --bs-btn-hover-border-color: var(--ecume-125--hover);
    --bs-btn-hover-color: var(--ecume-125--hover);

    .block-bg-dark & {
        --bs-btn-color: var(--bs-white);
        --bs-btn-border-color: var(--bs-white);
    }

    &:hover, &:focus {
        span {
            .block-bg-dark & {
                color: var(--bs-btn-hover-color)
            }
        }
    }
}

.btn-primary {
    .block-bg-dark & {
        --bs-btn-color: var(--ecume-125);
        --bs-btn-bg: var(--bs-white);
        --bs-btn-border-color: var(--bs-white);
        --bs-btn-hover-color: var(--ecume-125--hover);
        --bs-btn-hover-bg: var(--bs-white);
        --bs-btn-hover-border-color: var(--bs-white);
        --bs-btn-active-color: var(--ecume-125);
        --bs-btn-active-bg: var(--bs-white);
        --bs-btn-active-border-color: var(--bs-white);
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
        --bs-btn-disabled-color: var($gray-700);
        --bs-btn-disabled-bg: var($gray-200);
        --bs-btn-disabled-border-color: var($gray-200);

        color: var(--bs-btn-color)!important;
    }
}

.btn-to-top {
    position: fixed;
    right: 13.6rem;
    bottom: 5rem;
    width: 5.6rem;
    height: 5.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: .2rem solid var(--ecume-360);
    background: #fff;
    z-index: 9999;
    border-radius: .4rem;

    .material-symbols-outlined {
        transform: rotate(-90deg);
    }
}

.btn-popover {
    background: transparent;
    cursor: help;
    border: 0;
    padding: 0;
    border-bottom: .2rem dotted $ecume-360;
    color: $ecume-360;
    text-decoration: none ;

    &-form {
        .material-symbols-outlined {
            align-items: center;
            background: var(--bs-white);
            border-radius: 50%;
            color: rgb(var(--bs-info-rgb)) !important;
            display: flex;
            font-size: 1.7rem;
            height: 2rem;
            justify-content: center;
            width: 2rem;
        }
    }
}

.captcha-html-container button {
    border: 0;
}
