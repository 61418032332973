.breadcrumb {
    &-item {
        align-items: center;
        display: flex;

        a {
            text-decoration: underline;
        }

        &:first-child {
            a {
                padding-left: 0;
            }
        }

        span.nav-link {
            &:hover {
                .block-bg-dark & {
                    color: var(--bs-white);
                }
            }
        }

        .nav-link {
            &:focus-visible {
                box-shadow: 0 0 0 .25rem var(--ecume-360);

                .block-bg-dark & {
                    box-shadow: 0 0 0 .25rem var(--bs-white);
                }
            }
        }

        a {
            padding: 0 .4rem;
            border-radius: .2rem;

            &:hover {
                background: $gray-100;

                .block-bg-dark & {
                    background: var(--ecume-125--hover);
                }
            }

            &:active {
                background: $gray-400;

                .block-bg-dark & {
                    background: var(--ecume-charte);
                }
            }
        }

        & + & {
            padding-left: 0;

            &::before {
                content: $incaicon-angle-right;
                font-family: $icomoon-font-family;
                font-size: 2.4rem;
                line-height: 1;
                padding: 0;

                .block-bg-dark & {
                    color: var(--bs-white);
                }
            }
        }

        &-lg {
            display: none;
        }

        &-sm {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        &.show {
            display: flex!important;
        }

        &.active {
            color: var(--ecume-125);

            &::before {
                padding-right: .4rem;
            }
        }


    }

    &-btn {
        border: none;
        padding: 0;
        background: transparent;
    }
}
