.footer {
    width: 100%;

    &-top {
        @include media-breakpoint-up(md) {
            border-top: .1rem solid $gray-200;
        }
    }

    &-middle {
        @include media-breakpoint-up(md) {
            border-top: .1rem solid $gray-200;
        }

        .row {
            @include media-breakpoint-up(xl) {
                --bs-gutter-x: 6.4rem;
            }
        }

        [class*='col-'] {
            @include media-breakpoint-up(md) {
                border-left: .1rem solid $gray-200;
            }

            &:first-child {
                @include media-breakpoint-up(md) {
                    border: 0;
                }
            }

            &:last-child {
                .accordion-item {
                    @include media-breakpoint-down(md) {
                        border: 0;
                    }
                }
            }
        }

        &-link {
            align-items: center;
            display: inline-flex;
            text-decoration: none;

            .text {
                font-weight: 800;
                margin-right: .8rem;
            }

            .material-symbols-outlined {
                align-self: end;
            }

            &:hover, &:focus {
                .text {
                    text-decoration: underline;
                }
            }
        }

        .accordion {
            &-item {
                @include media-breakpoint-down(md) {
                    border-bottom: .1rem solid $gray-200;
                }
            }

            &-header {
                font-size: 1.8rem;
                font-weight: 800;
            }

            &-button {
                font-size: 1.8rem;
                font-weight: 800;
                justify-content: space-between;
                line-height: 1.4444;
                padding: 1.6rem 0;

                &[aria-expanded='true'] {
                    .material-symbols-outlined {
                        transform: rotate(180deg);
                    }
                }

                &::after {
                    display: none;
                }

                &:focus {
                    outline: solid;
                }
            }

            &-collapse {
                &:not(.show) {
                    @include media-breakpoint-up(md) {
                        display: block;
                    }
                }

                ul {
                    li {
                        line-height: normal;
                        margin-top: 1.2rem;

                        @include media-breakpoint-down(md) {
                            margin-top: 2.4rem;
                        }

                        &:first-child {
                            @include media-breakpoint-down(md) {
                                margin-top: .3rem;
                            }
                        }

                        a {
                            text-decoration: none;

                            &:hover, &:focus {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }

    &-bottom {
        background: $ecume-125;
        color: $white;

        a {
            color: $white;
            line-height: normal;
            text-decoration: none;

            &:hover, &:focus {
                text-decoration: underline;
            }
        }
    }
}
