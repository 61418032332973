.dropdown-lang {
    @include media-breakpoint-down(md) {
        margin-top: 1rem;
    }

    .dropdown {
        &-toggle {
            --bs-btn-border-width: 0;
            --bs-btn-font-size: 1.6rem;
            --bs-btn-font-weight: 400;
            --bs-btn-line-height: normal;
            --bs-btn-padding-x: 0;
            --bs-btn-padding-y: 0;

            gap: .8rem;

            &[aria-expanded='true'] {
                .material-symbols-outlined {
                    transform: rotate(180deg);
                }
            }

            &::after {
                display: none;
            }
        }

        &-menu {
            --bs-dropdown-bg: var(--ecume-125);
            --bs-dropdown-link-hover-color: var(--ecume-975);
            --bs-dropdown-link-hover-bg: var(--ecume-125);
        }
    }
}
